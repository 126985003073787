import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demotwo',
  templateUrl: './demotwo.component.html',
  styleUrls: ['./demotwo.component.css']
})
export class DemotwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
