<script src="../../../../../fap.git/warehouse/assets/js/barcode.js"></script>
<app-navbar></app-navbar>
<div class="fake-body">
    <div class="container">

      <!-- Toast -->
      <div style="position: absolute; top: 0; right: 0; z-index:1060;">

        <!-- Then put toasts within -->
        <div class="toast m-3 fade hide" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 300px;">
          <div class="toast-header">
            <img src="" class="rounded mr-2" alt="">
            <strong class="mr-auto">Barcode Scanned</strong>
            <small class="text-muted">just now</small>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="toast-body" id="toast-body" >
            Code: 
          </div>
        </div>

        </div>
        <!-- /Toast -->

        <!-- Modal -->
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">{{ orderType == 'PurchaseOrder' ? 'Receive Items' : 'Pick Items' }}</h5>
                    <div>
                        Pallet
                        <label class="switch">
                            <input type="checkbox" checked name="toogleScan" class="toogleScan" (click)="toogleScanBox();">
                            <span class="slider round"></span>
                        </label>
                        Case by Case
                    </div>
                    <div>
                        <button type="button"  class="btn btn-secondary" (click)="cancelChanges()">Cancel Changes</button>
                        &nbsp;
                    <button type="button" class="btn btn-primary" (click)="saveChanges()">Save Changes</button>
                    </div>
                <!--
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                -->
                </div>
                <div class="modal-body">
                    <div class="row">
                            <div class="col-md-4">Item Name</div>
                            <div class="col-md-4 text-center">Ordered Qty</div>
                            <div class="col-md-4 text-center">{{ orderType == 'PurchaseOrder' ? 'Received Quantity' : 'Picked Quantity' }}</div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4" id="item_name">{{productname}}</div>
                        <div class="col-md-4 text-center" id="qty_ordered">{{qty_ordered}}</div>
                        <div class="col-md-4 text-center" id="qty_received">
                            <span id="txt_qty">{{assetCountBox}}</span>
                            <input type="text" value="{{assetCountCase}}" id="input_qty_received" class="form-control" style="display:none"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-left">Wand Scanning Input</div>
                        <div class="col-md-4 text-center">
                            <input type="text" value="" id="wand-input" class="form-control" style=""/>
                        </div>
                    </div>
                    <!-- Barcode Scan stuff -->
                    <div class="controls">
                        <div class="input-group">
                          <div class="custom-file">
                            <input type="file" class="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"  accept="image/*;capture=camera">
                            <label class="custom-file-label" for="inputGroupFile04">Take or Upload a Photo...</label>
                          </div>
                        </div>
                        <br>
                        <p>Scanned Barcodes:</p>
                        <!-- <button class="btn btn-primary" id="rerun">Rerun</button> -->
                        <!-- Controls -->
                        <!--
                        <div class="reader-config-group">
                            <fieldset class="reader-config-group">
                                <label>
                                    <span>Barcode-Type</span>
                                    <select name="decoder_readers">
                                        <option value="code_128" selected="selected">Code 128</option>
                                        <option value="code_39">Code 39</option>
                                        <option value="code_39_vin">Code 39 VIN</option>
                                        <option value="ean">EAN</option>
                                        <option value="ean_extended">EAN-extended</option>
                                        <option value="ean_8">EAN-8</option>
                                        <option value="upc">UPC</option>
                                        <option value="upc_e">UPC-E</option>
                                        <option value="codabar">Codabar</option>
                                        <option value="i2of5">Interleaved 2 of 5</option>
                                        <option value="2of5">Standard 2 of 5</option>
                                        <option value="code_93">Code 93</option>
                                    </select>
                                </label>
                                <label>
                                    <span>Resolution (width)</span>
                                    <select name="input-stream_constraints">
                                        <option value="320x240">320px</option>
                                        <option selected="selected" value="640x480">640px</option>
                                        <option value="800x600">800px</option>
                                        <option value="1280x720">1280px</option>
                                        <option value="1600x960">1600px</option>
                                        <option value="1920x1080">1920px</option>
                                    </select>
                                </label>
                                <label>
                                    <span>Patch-Size</span>
                                    <select name="locator_patch-size">
                                        <option value="x-small">x-small</option>
                                        <option value="small">small</option>
                                        <option selected="selected" value="medium">medium</option>
                                        <option value="large">large</option>
                                        <option value="x-large">x-large</option>
                                    </select>
                                </label>
                                <label>
                                    <span>Half-Sample</span>
                                    <input type="checkbox" checked="checked" name="locator_half-sample" />
                                </label>
                                <label>
                                    <span>Workers</span>
                                    <select name="numOfWorkers">
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option selected="selected" value="4">4</option>
                                        <option value="8">8</option>
                                    </select>
                                </label>
                                <label>
                                    <span>Camera</span>
                                    <select name="input-stream_constraints" id="deviceSelection">
                                    </select>
                                </label>
                                <label style="display: none">
                                    <span>Zoom</span>
                                    <select name="settings_zoom"></select>
                                </label>
                                <label style="display: none">
                                    <span>Torch</span>
                                    <input type="checkbox" name="settings_torch" />
                                </label>
                            </fieldset>
                        </div>
                        -->
                        <!-- Controls -->
                    </div> <!-- controls -->
                    <div id="result_strip">
                        <ul class="thumbnails"></ul>
                        <ul class="collector"></ul>
                    </div>
                    <div id="interactive" class="viewport"></div>
                    <div id="debug" class="detection"></div>
                    <input id="code_type" hidden value="">
                    <input id="barcode_value" hidden value="">
                    <!-- /Barcode Scan Stuff -->
                </div>
                <!--
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" >Save changes</button>
                </div>
                -->
            </div>
            </div>
        </div>
        <!-- /Modal -->

        <!--Modal alert-->
        <div class="modal fade" id="globalModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body" id="modal-body-container">
                        <h5>Are you sure you'd like to cancel your changes?</h5>
                        <div align="center" style="margin-top: 10px">
                            <button type="button" class="btn btn-primary" style="margin-right: 10px"  data-dismiss="modal" id="remove_barcodes_trigger">Confirm</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="$('#exampleModalCenter').modal('show');">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Alert-->

        <!--Modal Cancel Order alert-->
        <div class="modal fade" id="globalModalTwo" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body" id="modal-body-container">
                        <h5>Are you sure you'd like to cancel your changes?</h5>
                        <div align="center" style="margin-top: 10px">
                            <button type="button" class="btn btn-primary" style="margin-right: 10px"  data-dismiss="modal" (click)="goToOrders()">Yes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" >No</button>
                        </div>
                    </div>
                </div>
            </div>

        <!-- Hidden input to remove failed barcode -->
        <input type="hidden" id="trigger_on_fail" name="trigger_on_fail">
        <!-- Hidden input to remove failed barcode -->
        
        </div>
        <!-- Cancel Order Alert-->



        <div class="top-section mt-3" *ngIf="orderType == 'PurchaseOrder'">
            <div class="row">
                <h3 class="col-sm">Order Details</h3>
               <!-- <h5 class="col-sm">Order Number: {{ orderDetail ?  orderDetail['purchaseorderid'] : '' }}</h5> -->
            </div>
            <div class="row">
                <p class="col-sm">Vendor: {{ orderDetail ?  orderDetail['vendorname'] : '' }}</p>
                <p class="col-sm">Status: <span class="OrderStatus">{{ orderDetail ?  orderDetail['postatus'] : '' }}</span></p>
            </div>
            <div class="row">
                <p class="col-sm">PO Number: {{ orderDetail ?  orderDetail['requisition_no'] : '' }}</p>
                <p class="col-sm">Date : {{ orderDetail ?  orderDetail['duedate'] : '' }}</p>
            </div>
            <div class="row">
                <p class="col-sm">Job Name: {{ orderDetail ?  orderDetail['subject'] : '' }}</p>
            </div>
        </div>
        <div class="top-section mt-3" *ngIf="orderType == 'SalesOrder'">
            <div class="row">
                <h3 class="col-sm">Order Details</h3>
                <h5 class="col-sm">Order Number: {{ orderDetail ?  orderDetail['salesorderid'] : '' }}</h5>
            </div>
            <div class="row">
                <p class="col-sm">Dealer: {{ orderDetail ?  orderDetail['accountname'] : '' }}</p>
                <p class="col-sm">Status: <span class="OrderStatus">{{ orderDetail ?  orderDetail['sostatus'] : '' }}</span></p>
            </div>
            <div class="row">
                <p class="col-sm">SO Number: {{ orderDetail ?  orderDetail['cf_960'] : '' }}</p>
                <p class="col-sm">Date : {{ orderDetail ?  orderDetail['duedate'] : '' }}</p>
            </div>
            <div class="row">
                <p class="col-sm">Job Name: {{ orderDetail ?  orderDetail['subject'] : '' }}</p>
            </div>
        </div>
        <div class="items-list">
            <h3>Products</h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>MFR</th>
                        <th>Product Name</th>
                        <th>Description</th>
                        <th>MFR Part No</th>
                        <!--<th style="display: none;">Line #</th>-->
                        <th>Ordered Quantity</th>
                        <th>{{ orderType == 'PurchaseOrder' ? 'Received Quantity' : 'Picked Quantity' }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="orderItem">
                    <tr *ngFor="let item of orderItem;let i = index;" [attr.data-index]="i">
                        <td>{{item['vendorname']}}</td>
                        <td [attr.data-productid]="item['productid']" class="lineItemName" [attr.data-lineitemid]="item['lineitem_id']">{{ item['productname'] }}</td>
                        <td>{{item['description']}}</td>
                        <td>{{item['productcode']}}</td>
                       <!-- <td style="display: none;" class="lineItemSeq" [attr.data-validbarcodes]="item['valid_barcode'] | json">{{ item['sequence_no'] }}</td>-->
                        <td class="itemqty" [attr.data-validbarcodes]="item['valid_barcode'] | json">{{ item['quantity'] | number:'1.0-0'}}</td>
                        <td class="itemqty_received"> {{item['qty_received'] | number:'1.0-0'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="button-holder my-4">
            <div class="btn btn-primary btn-lg save-order-button" (click)="createAssets($event)">Save Order</div>
            &nbsp;
            <div class="btn btn-danger btn-lg save-order-button" (click)="cancelOrderChanges()" >Cancel</div>
        </div>
    </div>
</div>


