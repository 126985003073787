<nav class="navbar navbar-dark bg-dark" >
    <div class="container">
        <a class="navbar-brand" href="./orders">
            <img src="https://getbootstrap.com/docs/4.5/assets/brand/bootstrap-solid.svg" width="30" height="30" class="d-inline-block align-top" alt="" loading="lazy">
            Barcodes
        </a>
        <div class="float-right row">
            <div class="col-md">
                <h3 class="mb-0"><span id="status" class="badge badge-secondary">Status</span></h3>
            </div>
            <a class="btn btn-info" href="./orders">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
                Orders List</a>
            <div class="btn-group col-md">
                <button type="btn" class="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Logout
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="dropdown-divider"></div>-->
                    <button class="dropdown-item" (click)="logout()">Logout</button>
                </div>
            </div>
        </div>
    </div>
</nav>
