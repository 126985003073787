<div class="fake-body-login text-center">
    <!-- Toast Position it -->
    <div style="position: absolute; top: 0; right: 0; z-index:10;">
        <!-- Then put toasts within -->
        <div class="toast m-3" id="notif" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 300px;">
          <div class="toast-header">
            <img src="" class="rounded mr-2" alt="">
            <strong class="mr-auto">Login Error</strong>
            <small class="text-muted">just now</small>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="toast-body" id="toast-body" >
            Login Error
          </div>
        </div>
      </div>
      <!-- /Toast Position it -->
    <form [formGroup]="loginForm" class="form-signin" (ngSubmit)="login(loginForm.value)">
        <img class="mb-4" src="https://getbootstrap.com/docs/4.5/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72">
        <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
        <div class="form-label-group">
            <label for="inputEmail" class="sr-only">Email address</label>
            <input formControlName="username" name="email" type="email" id="inputEmail" class="form-control" placeholder="Email address" required="" autofocus="" >
        </div>
        <div class="form-label-group">
            <label for="inputPassword" class="sr-only">Password</label>
            <input formControlName="password" name="password" type="password" id="inputPassword" class="form-control" placeholder="Password" required="" >
        </div>
        <div class="checkbox mb-3">
        <label>
            <input type="checkbox" value="remember-me"> Remember me
        </label>
        </div>
        <button class="btn btn-lg btn-primary btn-block" type="submit" >Sign in</button>
        <p class="mt-5 mb-3 text-muted">© 2020</p>
    </form>
</div>