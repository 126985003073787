<div id="main">
    <div class="container clearfix" aria-live="polite" aria-atomic="true" style="position: relative; min-height: 200px;">
        
      <!-- Position it -->
      <div style="position: absolute; top: 0; right: 0; z-index:10;">

        <!-- Then put toasts within -->
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 300px;">
          <div class="toast-header">
            <img src="" class="rounded mr-2" alt="">
            <strong class="mr-auto">Barcode Scanned</strong>
            <small class="text-muted">just now</small>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="toast-body" id="toast-body" >
            Code: 
          </div>
        </div>

        </div>


      <h1>Barcode Test <span class="badge badge-secondary" id="status">Status</span></h1>
        <!-- Deprecating, new badge method being used instead <div class="onlinedetector"><p id="msg"></p></div> -->
        <div class="controls">
            <div class="input-group">
              <div class="custom-file">
                <input type="file" class="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"  accept="image/*;capture=camera">
                <label class="custom-file-label" for="inputGroupFile04">Take or Upload a Photo...</label>
              </div>
            </div>
            <br>
            <button class="btn btn-primary" id="rerun">Rerun</button>
            <div class="reader-config-group">
                <fieldset class="reader-config-group">
                    <label>
                        <span>Barcode-Type</span>
                        <select name="decoder_readers">
                            <option value="code_128" selected="selected">Code 128</option>
                            <option value="code_39">Code 39</option>
                            <option value="code_39_vin">Code 39 VIN</option>
                            <option value="ean">EAN</option>
                            <option value="ean_extended">EAN-extended</option>
                            <option value="ean_8">EAN-8</option>
                            <option value="upc">UPC</option>
                            <option value="upc_e">UPC-E</option>
                            <option value="codabar">Codabar</option>
                            <option value="i2of5">Interleaved 2 of 5</option>
                            <option value="2of5">Standard 2 of 5</option>
                            <option value="code_93">Code 93</option>
                        </select>
                    </label>
                    <label>
                        <span>Resolution (width)</span>
                        <select name="input-stream_constraints">
                            <option value="320x240">320px</option>
                            <option selected="selected" value="640x480">640px</option>
                            <option value="800x600">800px</option>
                            <option value="1280x720">1280px</option>
                            <option value="1600x960">1600px</option>
                            <option value="1920x1080">1920px</option>
                        </select>
                    </label>
                    <label>
                        <span>Patch-Size</span>
                        <select name="locator_patch-size">
                            <option value="x-small">x-small</option>
                            <option value="small">small</option>
                            <option selected="selected" value="medium">medium</option>
                            <option value="large">large</option>
                            <option value="x-large">x-large</option>
                        </select>
                    </label>
                    <label>
                        <span>Half-Sample</span>
                        <input type="checkbox" checked="checked" name="locator_half-sample" />
                    </label>
                    <label>
                        <span>Workers</span>
                        <select name="numOfWorkers">
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option selected="selected" value="4">4</option>
                            <option value="8">8</option>
                        </select>
                    </label>
                    <label>
                        <span>Camera</span>
                        <select name="input-stream_constraints" id="deviceSelection">
                        </select>
                    </label>
                    <label style="display: none">
                        <span>Zoom</span>
                        <select name="settings_zoom"></select>
                    </label>
                    <label style="display: none">
                        <span>Torch</span>
                        <input type="checkbox" name="settings_torch" />
                    </label>
                </fieldset>
            </div>
        </div> <!-- controls -->
        <div id="result_strip">
            <ul class="thumbnails"></ul>
            <ul class="collector"></ul>
        </div>
        <div id="interactive" class="viewport"></div>
        <div id="debug" class="detection"></div>
    </div> <!-- container -->
</div> <!-- main -->


<script>
    var options = {
        delay: 2000,
    };
    $(".toast").toast(options);

</script>