<app-navbar></app-navbar>
<div class="fake-body">
    <div class="container">
        <!-- Toast Position it -->
        <div style="position: absolute; top: 0; right: 0; z-index:10;">
            <!-- Then put toasts within -->
            <div class="toast m-3 mt-4" id="notif" role="alert" aria-live="assertive" aria-atomic="true"
                 style="min-width: 300px;">
                <div class="toast-header">
                    <img src="" class="rounded mr-2" alt="">
                    <strong class="mr-auto">Orders Loaded</strong>
                    <small class="text-muted">just now</small>
                    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="toast-body" id="toast-body">
                    Orders loaded from:
                </div>
            </div>
        </div>
        <!-- /Toast Position it -->

        <div class="mt-3">
            <div class="btn-group btn-group-lg btn-group-toggle" data-toggle="buttons" aria-label="View Select">
                <label class="btn btn-secondary active">
                    <input type="radio" name="options" id="PurchaseOrder" value="PurchaseOrder" (click)="updateView($event)" checked> Purchase Orders
                </label>
                <label class="btn btn-secondary">
                    <input type="radio" name="options" id="SalesOrder" value="SalesOrder" (click)="updateView($event)"> Sales Orders
                </label>
                <!-- Not using Invoices on this view 
                <label class="btn btn-secondary">
                    <input type="radio" name="options" id="Invoice" value="Invoice" (click)="updateView($event)"> Invoices
                </label>
                -->
            </div>

            <div class="btn btn-primary btn-lg btn-success" style="margin-left: 20px;width: 80px;" (click)="openScanBarcodePopup();">POS</div>
            <!-- Hiding because we're using datatable search instead -->
            <!--
            <div class="float-right">
                <form class="form-inline ">
                    <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-primary " type="submit">Search</button>
                </form>
            </div>
            -->
        </div>
        <div class="dataTable-container mt-3">
            <div id="myTable">

            </div>
        </div>
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="line-scale">
            <p style="color: white">Please Wait... </p>
        </ngx-spinner>

        <!-- Modal -->
        <div class="modal fade" id="barcodeScanPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
            <form id="barcode-pos-form">
            <div id="barcode-scan-event"></div>
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="max-width: 950px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Receive Items</h5>
                        <div>
                            Pallet
                            <label class="switch">
                                <input type="checkbox" checked name="toogleScan" class="toogleScan" (click)="toogleScanBox();"/>
                                <span class="slider round"></span>
                            </label>
                            Case by Case
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row form-group">
                            <div class="col-md-6 row">
                                <div class="col-md-4">SO #</div>
                                <div class="col-md-8"><input class="form-control" name="pos-number" id="pos-number"/></div>
                            </div>
                            <div class="col-md-6 row">
                                <div class="col-md-4">Organization</div>
                                <div class="col-md-8">
                                    <input class="form-control"  autocomplete="nope"   name="pos-dealer-name" id="pos-dealer-name"/>
                                    <input type="hidden" class="form-control" name="pos-dealer" id="pos-dealer"/>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-6 row">
                                <div class="col-md-4">Vendor Name</div>
                                <div class="col-md-8">
<!--                                    <input class="form-control" name="pos-vendor" id="pos-vendor"/>-->
                                    <select name="pos-vendor" id="pos-vendor" class="form-control">
                                        <option *ngFor="let item of vendorList;" value="{{item.id}}">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6 row">
                                <div class="col-md-4">Contact Name</div>
                                <div class="col-md-8"><input class="form-control" name="pos-contact" id="pos-contact"/></div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-6 row">
                                <div class="col-md-4">Job Name</div>
                                <div class="col-md-8"><input class="form-control" name="pos-job" id="pos-job"/></div>
                            </div>
                            <div class="col-md-6 row">
                                <div class="col-md-4">Date</div>
                                <div class="col-md-8">
                                    <input class="form-control dateField"  autocomplete="nope"  name="pos-date" id="pos-date" placeholder="mm/dd/yyyy" data-format="mm/dd/yyyy" value="{{currentDate | date:'MM/dd/yyyy'}}"/>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-6 row">
                                <div class="col-md-4">Assigned To</div>
                                <div class="col-md-8">
                                    <select name="pos-assignto" id="pos-assignto" class="form-control">
                                        <option *ngFor="let item of userList;" value="{{item.id}}">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6 row"></div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-4 text-left">Wand Scanning Input</div>
                            <div class="col-md-4 text-center">
                                <input type="text" value="" id="wand-input" class="form-control" style=""/>
                            </div>
                        </div>
                        <!-- Barcode Scan stuff -->
                        <div class="controls">
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"  accept="image/*;capture=camera">
                                    <label class="custom-file-label" for="inputGroupFile04">Take or Upload a Photo...</label>
                                </div>
                            </div>
                            <br>
                            <p>Scanned Barcodes:</p>
                        </div>
                        <div id="result_strip">
                            <ul class="thumbnails"></ul>
                            <ul class="collector"></ul>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-9">Item Name</div>
                            <div class="col-md-3 text-center">Picked Quantity</div>
                        </div>
                        <div id="popup-list-item">

                        </div>
                        <div id="interactive" class="viewport"></div>
                        <div id="debug" class="detection"></div>
                        <input id="code_type" hidden value="">
                        <input id="barcode_value" hidden value="">
                        <!-- /Barcode Scan Stuff -->
                    </div>
                    <div class="modal-footer">
                        <div style="margin: 0 auto;">
                            <button type="button" class="btn btn-primary" style="margin-right: 10px;" (click)="submitPosForm()">Save Changes</button>
                            <button type="button"  class="btn btn-secondary" (click)="cancelChanges()">Cancel Changes</button>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        </div>
        <!-- /Modal -->

        <!--Modal alert-->
        <div class="modal fade" id="barcodeScanPopupConfirm" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body" id="modal-body-container">
                        <h5>Are you sure you'd like to cancel your changes?</h5>
                        <div align="center" style="margin-top: 10px">
                            <button type="button" class="btn btn-primary" style="margin-right: 10px"  data-dismiss="modal" id="confirm-hide-barcode-popup">Confirm</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="$('#barcodeScanPopup').modal('show');">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Alert-->

    </div>
</div>
